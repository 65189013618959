import Crud from "./crud"
import { BulkImportData, CreateContactResponse, Contact } from "@/shared/models/contact"
import { Client } from "@/shared/services/client"
import { Tag } from "@/shared/models/tag"
import { EditableAddressPayload, EditableEmail, EditablePhonePayload } from "@/shared/models/contact-information"
import { ACL } from "@/shared/models/role"

const client = new Client()

export class ContactService extends Crud<Contact> {
    constructor() {
        super("crm/contacts")
    }

    bulkDelete(ids: string[]): Promise<{ deletedCount: number }> {
        return client.post(`${this.endpoint}/bulk/delete`, {
            ids,
        })
    }

    bulkImport(data: BulkImportData) {
        const files = new FormData()
        if (!data) {
            return
        }
        if (Array.isArray(data.file)) {
            data.file.forEach((file) => {
                files.append("files[]", file)
            })
        } else {
            files.append("file", data.file)
        }
        files.append("type", data.type)

        return client.post(`${this.endpoint}/bulk/import`, files)
    }

    getAssignedAgentsACLs(ids: string) {
        return client.get(`${this.endpoint}/permissions`, {
            params: {
                ids,
            },
        })
    }

    addContact(data: Partial<Contact>): Promise<CreateContactResponse> {
        return client.post(this.endpoint, data)
    }

    updateContact(id: string, data: Partial<Contact>): Promise<{ contact: Contact }> {
        return client.patch(`${this.endpoint}/${id}`, data)
    }

    bulkUpdateTagOnContact(contactIds: string[], toAdd: Tag[], toRemove: Tag[], all: boolean) {
        return client.post(`${this.endpoint}/bulk/update/tags`, {
            ids: contactIds,
            all,
            updates: {
                toAdd,
                toRemove,
            },
        })
    }

    bulkUpdatePermissionsOnContact(
        contactIds: string[],
        toAdd: ACL[],
        toRemove: string[],
        listsToAdd: ACL[],
        listsToRemove: string[],
        all: boolean
    ) {
        return client.post(`${this.endpoint}/bulk/update/acl`, {
            ids: contactIds,
            updates: {
                toAdd,
                toRemove,
                listsToAdd,
                listsToRemove,
                all,
            },
        })
    }

    searchContacts(search: string, hasPhone: undefined | boolean, limit: undefined | number): Promise<Contact[]> {
        return client.get(`${this.endpoint}/autocomplete`, {
            params: {
                search,
                hasPhone,
                limit,
            },
        })
    }

    updateContactsEmail(contactId: string, emails: EditableEmail[]) {
        return client.put(`${this.endpoint}/${contactId}/emails`, { emails })
    }

    updateContactsPhone(contactId: string, phones: EditablePhonePayload[]) {
        return client.put(`${this.endpoint}/${contactId}/phones`, { phones })
    }

    updateContactsAddress(contactId: string, addresses: EditableAddressPayload[]) {
        return client.put(`${this.endpoint}/${contactId}/addresses`, { addresses })
    }

    updateStage(contactId: string, stage: Tag) {
        const payload = !Object.keys(stage).length ? {} : { stage }
        return client.put(`${this.endpoint}/${contactId}/stage`, payload)
    }

    shareContact(contactId: string, agentsEmails: string[], permissionType: string) {
        return client.post(`${this.endpoint}/share/${contactId}`, { agentsEmails, permissionType })
    }

    unsubscribeContact(token: string, contactId: string) {
        return client.get(`${this.endpoint}/unsubscribe`, { params: { token, contactId } })
    }

    checkContactList(typeName: string, entityIds: string[]) {
        return client.get(`${this.endpoint}/listCheck`, { params: { typeName, entityIds } })
    }
}
