<template>
    <div
        class="flex items-center justify-between shrink-0 md:gap-x-4 px-4 py-2 md:px-0 md:py-0 border-b-2 md:border-b-0"
    >
        <!-- Menu action items -->
        <div v-for="(item, index) in menuActionItems" :key="index">
            <GgmsButton
                styleType="simple"
                :isMenuActionItems="true"
                :classes="['rounded-full w-10 h-10 px-0 py-0']"
                @click="item.onClick"
            >
                <component :is="item.icon" class="h-6 w-6 text-gray-400" />
            </GgmsButton>
        </div>
        <!-- Profile dropdown -->
        <Menu as="div" class="relative z-50">
            <MenuButton
                class="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color-500"
            >
                <img
                    v-if="currentUser?.profilePicture?.url"
                    :src="currentUser.profilePicture.url"
                    class="h-8 w-8 rounded-full object-cover"
                />
                <div v-else class="h-8 w-8 rounded-full bg-gray-300 flex justify-center items-center">
                    <span class="text-gray-700">
                        {{ getInitials(currentUser?.fullName || "X Y") }}
                    </span>
                </div>
            </MenuButton>
            <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
            >
                <MenuItems
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                    <MenuItem v-for="item in userNavigation" :key="item.name">
                        <a
                            class="block px-4 py-2 text-sm text-gray-700 border-b last:border-b-0 cursor-pointer hover:bg-gray-100"
                            @click="item.onClick"
                        >
                            <div class="flex items-center gap-x-3">
                                <component :is="item.icon" class="h-5 w-5 text-gray-400" />
                                <p>{{ item.name }}</p>
                            </div>
                        </a>
                    </MenuItem>
                </MenuItems>
            </transition>
        </Menu>
    </div>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { useRouter } from "vue-router"
import GgmsButton from "@/components/GgmsButton.vue"
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue"
import {
    PhoneIcon,
    MailIcon,
    ChatAltIcon,
    UserAddIcon,
    BellIcon,
    UserIcon,
    OfficeBuildingIcon,
    ViewGridIcon,
    InformationCircleIcon,
    LogoutIcon,
    CreditCardIcon,
} from "@heroicons/vue/outline"
import { useAuthStore } from "@/stores/auth"
import { useSocketStore } from "@/stores/socket"
import { useMainStore } from "@/stores/main"
import { useAgencyThemeStore } from "@/stores/agency-theme"
import { useNavigationSidebarStore } from "@/stores/navigation-sidebar"
import { User } from "@/shared/models/user"
import { clearStorage, getInitials } from "@/shared/utils/helpers"
import { setColorShades } from "@/shared/utils/color-helpers"
import { useWorkflowStore } from "@/stores/workflow"
import { useFormStore } from "@/stores/form"

const router = useRouter()
const authStore = useAuthStore()
const socketStore = useSocketStore()
const mainStore = useMainStore()
const agencyThemeStore = useAgencyThemeStore()
const navigationSidebarStore = useNavigationSidebarStore()
const workflowStore = useWorkflowStore()
const formStore = useFormStore()

const emit = defineEmits(["openPhoneSidebar", "openAddContactModal", "openNotificationSidebar"])

const menuActionItems = [
    {
        icon: PhoneIcon,
        onClick: () => emit("openPhoneSidebar"),
    },
    {
        icon: MailIcon,
        onClick: () => console.log("MailIcon"),
    },
    {
        icon: ChatAltIcon,
        onClick: () => console.log("ChatAltIcon"),
    },
    {
        icon: UserAddIcon,
        onClick: () => emit("openAddContactModal"),
    },
    {
        icon: BellIcon,
        onClick: () => emit("openNotificationSidebar"),
    },
]

const userNavigation = [
    { name: "My Settings", icon: UserIcon, onClick: navigateToMySettings },
    { name: "Company Settings", icon: OfficeBuildingIcon, onClick: navigateToSettings },
    { name: "Plans & Billing", icon: CreditCardIcon, onClick: navigateToBilling },
    { name: "Product Changes", icon: ViewGridIcon, onClick: undefined },
    { name: "System Status", icon: InformationCircleIcon, onClick: undefined },
    { name: "Log Out", icon: LogoutIcon, onClick: logout },
]

const currentUser = computed(() => authStore.currentUser)

async function navigateToSettings() {
    navigationSidebarStore.toggleSidebar(false)
    await router.push({ name: "companySettings" })
}

async function navigateToBilling() {
    navigationSidebarStore.toggleSidebar(false)
    await router.push({ name: "billing" })
}

async function navigateToMySettings() {
    navigationSidebarStore.toggleSidebar(false)
    await router.push({ name: "mySettings" })
}

async function logout() {
    if (workflowStore.hasUnsavedChanges || formStore.hasUnsavedChanges) {
        await router.push({ name: "login" })
        return
    }
    await authStore.logout()
    clearStorage()
    authStore.currentUser = {} as User
    setColorShades("--primary-color")
    setColorShades("--link-color")
    socketStore.disconnectSocket()
    await router.push({ name: "login" })
    mainStore.resetAll()
    await agencyThemeStore.getAgencyThemeBySubdomain()
}
</script>
