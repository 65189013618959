import { Client } from "@/shared/services/client"
import { PagedResponse } from "@/shared/models/paged-response"
import { TableQueryParams } from "@/shared/models/table-query-params"
import { TrackEvent } from "@/shared/models/track-event"

const client = new Client()

export class TrackEventService {
    getAll(queryParams: TableQueryParams): Promise<PagedResponse<TrackEvent>> {
        return client.get(`${process.env.VUE_APP_BEACON_API_URL}/tracker`, { params: queryParams })
    }
}
