<template>
    <GgmsModal :open="open" @closeModal="closeCreateTemplateModal" @keydown.enter.prevent="handleEnterKey">
        <template #header>
            <div class="flex justify-start items-center text-primary-color-600">
                <div class="flex items-center justify-center mr-4 h-12 w-12 rounded-full bg-primary-color-100">
                    <DocumentTextIcon class="h-6 w-6 text-primary-color-600" />
                </div>
                <h1
                    class="text-lg contacting-6 font-medium last:text-gray-900 cursor-pointer last:cursor-auto"
                    @click="closePreview"
                >
                    {{ createModalTitle }} Template
                </h1>
                <h1 v-if="isPreviewOpen" class="text-lg contacting-6 font-medium text-gray-900">&nbsp;&gt; Preview</h1>
            </div>
        </template>
        <div class="flex flex-col gap-y-[30px]">
            <template v-if="!isPreviewOpen">
                <div class="flex gap-x-4 w-full">
                    <GgmsInput
                        v-model="localNewTemplate.displayName"
                        name="templateName"
                        label="Name"
                        placeholder="Name"
                        :error-message="hasErrorMessage('displayname', templateStore.validationError)"
                        :invalid="isInvalid('displayname', templateStore.validationError)"
                        class="w-1/2"
                    />
                    <div class="w-1/2">
                        <GgmsDropdown
                            v-model="localNewTemplate.templateType"
                            :options="templateTypes"
                            :disabled="typeDropdownDisabled"
                            label="Type"
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Select Template Type"
                            class="w-full"
                        />
                    </div>
                </div>
                <div class="flex gap-x-4 w-full">
                    <div class="w-[256px]">
                        <GgmsMultiSelect
                            v-if="domains.length"
                            v-model="localSelectedDomains"
                            :options="domains"
                            label="Domains"
                            placeholder="Select Domains"
                            optionLabel="url"
                            :disabled="isDomainsDisabled"
                            :invalid="isInvalid('domains', templateStore.validationError)"
                            :errorMessage="hasErrorMessage('domains', templateStore.validationError)"
                            classes="w-full"
                        />
                    </div>
                    <div class="w-[256px]">
                        <GgmsMultiSelect
                            v-model="localSelectedTags"
                            :options="tags"
                            label="Tags"
                            placeholder="Select Tags"
                            display="chip"
                            optionLabel="displayName"
                            classes="w-full"
                        />
                    </div>
                </div>
                <div v-if="isTemplateTypeEmail" class="flex flex-col gap-y-[30px]">
                    <GgmsInput
                        v-model="localNewTemplate.subject"
                        name="templateName"
                        label="Subject"
                        placeholder="Subject"
                        :template-string="tempVarForSubject"
                        :error-message="hasErrorMessage('subject', templateStore.validationError)"
                        :invalid="isInvalid('subject', templateStore.validationError)"
                        @click="setEmailInputFocus(false)"
                    />
                    <div class="flex flex-col gap-y-3">
                        <div class="flex justify-between">
                            <GgmsToggle v-model="isViewingFormatted" truth-label="Visual" false-label="Text" />
                            <GgmsMergeFields
                                :specialVariable="specialVariable"
                                :isTemplateTypeEmail="true"
                                @add-merge-field="addMergeFieldToEmail"
                            />
                        </div>
                        <div @click="setEmailInputFocus(true)">
                            <GgmsRichTextEditor
                                v-if="isViewingFormatted"
                                v-model="localNewTemplate.body"
                                placeholder="Write your email template..."
                                :error-message="hasErrorMessage('body', templateStore.validationError)"
                                @load="onEditorLoad"
                                @disable-validation-error="disableValidationError"
                            />
                            <GgmsTextarea
                                v-else
                                v-model="localNewTemplate.textBody"
                                textAreaClasses="min-h-[244px]"
                                placeholder="Write your email..."
                                :template-string="tempVarForBodyText"
                                :error-message="hasErrorMessage('body', templateStore.validationError)"
                                :invalid="isInvalid('body', templateStore.validationError)"
                                @disable-validation-error="disableValidationError"
                            />
                        </div>
                    </div>
                </div>
                <div v-else class="flex flex-col gap-y-3">
                    <GgmsMergeFields class="w-fit self-end" @add-merge-field="addMergeFieldToTextMessage" />
                    <GgmsTextarea
                        v-model="localNewTemplate.textBody"
                        textAreaClasses="min-h-[244px]"
                        placeholder="Write your text message..."
                        :template-string="tempVarForBodyText"
                        :is-count-available="true"
                        :error-message="hasErrorMessage('body', templateStore.validationError)"
                        :invalid="isInvalid('body', templateStore.validationError)"
                        :isForSMS="true"
                        @disable-validation-error="disableValidationError"
                    />
                    <GgmsEmojiPicker :isAlignedTop="true" @send-emoji="setEmojiInTextarea" />
                </div>
                <GgmsButton
                    styleType="secondary"
                    :disabled="!isPreviewAvailable"
                    :icon-start="ExternalLinkIcon"
                    @click="openPreview"
                    class="w-fit"
                    >Preview</GgmsButton
                >
            </template>
            <div v-else>
                <div v-if="isTemplateTypeEmail" class="flex flex-col gap-y-3">
                    <GgmsToggle v-model="isViewingFormatted" truth-label="Visual" false-label="Text" class="w-fit" />
                    <div class="w-full text-sm font-medium flex flex-col">
                        <p
                            class="border-t border-x border-gray-300 rounded-md py-2.5 px-3 text-sm font-medium text-gray-900"
                        >
                            Subject: &nbsp; {{ emailPreviewSubject }}
                        </p>
                        <div
                            v-if="isViewingFormatted"
                            v-html="emailPreviewText"
                            class="py-2.5 px-3 border border-gray-300 rounded-md"
                        ></div>
                        <GgmsTextarea
                            v-else
                            v-model="messagePreviewText"
                            :readonly="true"
                            textAreaClasses="min-h-[244px]"
                        />
                    </div>
                </div>
                <div v-else>
                    <GgmsTextarea v-model="messagePreviewText" :readonly="true" textAreaClasses="min-h-[244px]" />
                </div>
            </div>
        </div>
        <template #under>
            <div v-if="!isPreviewOpen" class="flex justify-between w-full">
                <GgmsButton
                    v-if="!isCreateTemplate && showDeleteButton"
                    styleType="active"
                    :disabled="isNotEditableByUser"
                    :classes="[
                        'text-red-600 bg-transparent border-transparent shadow-transparent focus:ring-red-600/50',
                    ]"
                    @click="openDeleteTemplateModal"
                    >Delete
                </GgmsButton>
                <div class="flex justify-end w-full">
                    <GgmsButton styleType="secondary" class="mr-3" @click="closeCreateTemplateModal">Cancel</GgmsButton>
                    <GgmsButton
                        :disabled="templateStore.tableState.isLoading || isNotEditableByUser"
                        @click="addNewTemplate()"
                    >
                        {{ createModalButtonText }}</GgmsButton
                    >
                </div>
            </div>
            <div v-else class="flex justify-end">
                <GgmsButton @click="closePreview">Close Preview</GgmsButton>
            </div>
        </template>
    </GgmsModal>
</template>
<script setup lang="ts">
import { computed, defineEmits, defineProps, ref } from "vue"
import { useRoute } from "vue-router"
import {
    hasErrorMessage,
    hasUserOnlyReadPermission,
    isInvalid,
    renderTemplate,
    stripHtml,
} from "@/shared/utils/helpers"
import { DocumentTextIcon, ExternalLinkIcon } from "@heroicons/vue/outline"
import Quill, { RangeStatic } from "quill"
import GgmsMergeFields from "@/components/GgmsMergeFields.vue"
import GgmsModal from "@/components/GgmsModal.vue"
import { Template, TemplatePayload } from "@/shared/models/template"
import GgmsMultiSelect from "@/components/GgmsMultiSelect.vue"
import GgmsTextarea from "@/components/GgmsTextarea.vue"
import GgmsEmojiPicker from "@/components/GgmsEmojiPicker.vue"
import GgmsToggle from "@/components/GgmsToggle.vue"
import GgmsDropdown from "@/components/GgmsDropdown.vue"
import GgmsInput from "@/components/GgmsInput.vue"
import GgmsButton from "@/components/GgmsButton.vue"
import GgmsRichTextEditor from "@/components/GgmsRichTextEditor.vue"
import { useTemplateStore } from "@/stores/template"
import { useConfigStore } from "@/stores/config"
import { useAgencyStore } from "@/stores/agency"
import { useMessageThemeStore } from "@/stores/message-theme"
import { useTagStore } from "@/stores/tag"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { GgmsError } from "@/shared/services/client"
import { Agency, AgencyDomain } from "@/shared/models/agency"
import { Tag } from "@/shared/models/tag"
import { Contact } from "@/shared/models/contact"
import { Agent } from "@/shared/models/agent"

interface Props {
    open: boolean
    isCreateTemplate: boolean
    isPreviewOpen: boolean
    newTemplate: Template
    isDomainsDisabled: boolean
    domains: AgencyDomain[]
    selectedDomains: AgencyDomain[]
    selectedTags: Tag[]
    tags: Tag[]
    showDeleteButton?: boolean
    typeDropdownDisabled?: boolean
}

const props = withDefaults(defineProps<Props>(), { showDeleteButton: true, typeDropdownDisabled: false })

const emit = defineEmits([
    "closeModal",
    "openPreview",
    "closePreview",
    "update:newTemplate",
    "openDelete",
    "update:selectedDomains",
    "update:selectedTags",
    "saveTemplate",
])

const route = useRoute()
const templateStore = useTemplateStore()
const configStore = useConfigStore()
const agencyStore = useAgencyStore()
const messageThemeStore = useMessageThemeStore()
const tagStore = useTagStore()
const gridCollectionStore = useGridCollectionStore()

const localNewTemplate = computed({
    get() {
        return props.newTemplate
    },
    set(value) {
        emit("update:newTemplate", value)
    },
})

const localSelectedDomains = computed({
    get() {
        return props.selectedDomains
    },
    set(value) {
        emit("update:selectedDomains", value)
    },
})

const localSelectedTags = computed({
    get() {
        return props.selectedTags
    },
    set(value) {
        emit("update:selectedTags", value)
    },
})

const agency = computed(() => agencyStore.agency)
const messagePreviewText = ref("")
const emailPreviewText = ref("")
const emailPreviewSubject = ref("")

const createModalTitle = computed(() => (props.isCreateTemplate ? "New" : "Update"))
const createModalButtonText = computed(() => (props.isCreateTemplate ? "Save" : "Update"))

const isViewingFormatted = ref(true)
const isTemplateTypeEmail = computed(() => localNewTemplate.value.templateType === "email")
const isEmailBodyFocused = ref(true)
const isPreviewAvailable = computed(
    () => !!(localNewTemplate.value.body?.length || localNewTemplate.value.textBody?.length)
)
let lastSelection = {} as RangeStatic

const quill = ref<Quill>()
const tempVarForSubject = ref("")
const tempVarForBodyText = ref("")

const modalData = ref({
    listName: "",
    listDescription: "",
    isPinned: false,
    isInputVisible: false,
})

const isLoading = ref(false)
const listModalActionText = ref("")
const error = ref()
const isListModalOpen = ref(false)
const haveFiltersAdded = ref(false)

const saveFilterOptions = [
    {
        name: "Save as New List",
        action: "saveList",
    },
]

const templateTypes = [
    { label: "Text Message", value: "sms" },
    { label: "Email", value: "email" },
]

const cachedTags = computed(() => tagStore.cachedTags)

const selectedCount = computed(() => {
    return templateStore.tableState.isAllSelected
        ? templateStore.tableState.total - templateStore.tableState?.selectedData?.length
        : templateStore.tableState?.selectedData?.length
})

const collections = computed(() =>
    gridCollectionStore.collectionTableState.data.filter((collection) => collection?.isPinned)
)
const grid = computed(() => gridCollectionStore.grid)
const prototype = computed(() => configStore.getPrototype("template"))

const isNotEditableByUser = computed(() => hasUserOnlyReadPermission(route.name as string))

const specialVariable = ref("")

function closeCreateTemplateModal() {
    emit("closeModal")
}

function closePreview() {
    emit("closePreview")
}

function setEmailInputFocus(isFocused: boolean) {
    isEmailBodyFocused.value = isFocused
}

function handleEnterKey(event: KeyboardEvent) {
    if (event.key === "Enter" && props.open) {
        addNewTemplate()
    }
}

function addMergeFieldToEmail(mergeFieldName: string) {
    // quill.value.updateContents(new Quill.Delta().insert(`{{${mergeFieldName}}}`))
    // Have to manually update the model value of the body, because no event will be emitted
    if (!isEmailBodyFocused.value) {
        tempVarForSubject.value = ""
        tempVarForSubject.value = mergeFieldName
        return
    }
    if (isViewingFormatted.value) {
        const currentSelection = quill.value?.getSelection(true)
        quill.value?.insertText(currentSelection?.index || -1, mergeFieldName)
        localNewTemplate.value.body = quill.value?.root?.innerHTML ?? ""
        return
    }
    addMergeFieldToTextMessage(mergeFieldName)
}

function addMergeFieldToTextMessage(mergeFieldName: string) {
    tempVarForBodyText.value = ""
    tempVarForBodyText.value = mergeFieldName
}

function onEditorLoad(editor: Quill) {
    quill.value = editor
    editor.on("selection-change", (range) => {
        if (range) {
            lastSelection = range
        }
    })
    editor.on("text-change", (delta) => {
        if (typeof delta.ops[0]?.insert === "string") {
            lastSelection.index += delta.ops[0]?.insert?.length
        }
    })
}

function disableValidationError() {
    templateStore.validationError = {} as GgmsError
}

function setEmojiInTextarea(emoji: string) {
    tempVarForBodyText.value = ""
    tempVarForBodyText.value = emoji
}

function openDeleteTemplateModal() {
    emit("openDelete")
}

function openPreview() {
    const models = {
        contact: {} as Contact,
        agency: agency.value as Agency,
        agent: {} as Agent,
    }
    const theme = getTheme() || ""
    let signature = configStore?.signature?.body || ""

    if (isTemplateTypeEmail.value && localNewTemplate.value.body) {
        const emailPreviewBody = localNewTemplate.value.body
        emailPreviewText.value = renderTemplate(emailPreviewBody, configStore.prototypes, models, theme, signature)
        emailPreviewSubject.value = localNewTemplate.value.subject
    }

    const messagePreviewBody =
        isTemplateTypeEmail.value && localNewTemplate.value.body
            ? stripHtml(localNewTemplate.value.body)
            : localNewTemplate.value.textBody

    signature = stripHtml(signature)

    messagePreviewText.value = renderTemplate(messagePreviewBody, configStore.prototypes, models, theme, signature)

    emit("openPreview")
}

function getTheme() {
    if (isTemplateTypeEmail.value && isViewingFormatted.value) {
        return getBodyOfMessageTheme("RichEmail")
    }
    if (isTemplateTypeEmail.value && !isViewingFormatted.value) {
        return getBodyOfMessageTheme("PlainTextEmail")
    }
    return getBodyOfMessageTheme("TextMessage")
}

function getBodyOfMessageTheme(channel: string) {
    return messageThemeStore.messageThemes.find((theme) => theme.channel === channel)?.body
}
async function addNewTemplate() {
    try {
        const templatePayload: TemplatePayload = {
            displayName: localNewTemplate.value.displayName as string,
            templateType: localNewTemplate.value.templateType,
            body:
                isViewingFormatted.value && isTemplateTypeEmail.value
                    ? localNewTemplate.value.body
                    : localNewTemplate.value.textBody,
        }
        if (isTemplateTypeEmail.value) {
            templatePayload.subject = localNewTemplate.value.subject
        }

        if (localSelectedDomains.value.length) {
            templatePayload.domainsIds = localSelectedDomains.value.map((domain) => domain._id)
        }

        templatePayload.tags = localSelectedTags.value

        const response = props.isCreateTemplate
            ? await templateStore.createTemplate(templatePayload)
            : await templateStore.updateTemplate(localNewTemplate.value._id, templatePayload)

        if (!response) {
            return
        }

        if (!props.isCreateTemplate) {
            await templateStore.getTemplates()
        }

        emit("saveTemplate")
        closeCreateTemplateModal()
    } catch (error) {
        console.log(error)
    }
}
</script>
